body {
  height: auto;
  width: auto;
}

.bttn-geniues-new {
  width: 20%;
  margin: 0 auto;
  font-size: 23px;
  background-color: #0170cd;
  border: none;
  color: #fff;
  font-weight: 700;
  /* font-family: 'Times New Roman', Times, serif; */
  text-shadow: #000;
  border-radius: 20px;
  padding: 10px 20px 12px 20px;
  /* float: right; */
  display: initial;
  font-weight: normal;
}

.mart35psupport {
  margin-top: 35px;
}
